import React, { useRef, useEffect, useState } from 'react';
import './stats.css';
import { Animator, Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default function Stats() {
    const axisTitle = 16;
    const barColor = 'rgba(255, 141, 71, 0.6)';
    const barBorder = 'rgba(255, 141, 71, 1)';

    useEffect(() => {
        const top10count = new Chart('top10count', {
            type: 'bar',
            data: {
                labels: ['East New York', 'Charleston-Richmond Valley-Tottenville',
                'Hunters Point-Sunnyside-West Maspeth',
                "Annadale-Huguenot-Prince's Bay-Eltingville",
                'Far Rockaway-Bayswater',
                'West New Brighton-New Brighton-St. George',
                'Carroll Gardens-Columbia Street-Red Hook', 'Jamaica',
                'Old Town-Dongan Hills-South Beach', 'Bedford'],
                datasets: [{
                    label: '# of LLC owned vacant parcels',
                    data: [224., 206., 191., 166., 163., 155., 146., 134., 129., 128.],
                    backgroundColor: [
                        barColor
                    ],
                    borderColor: [
                        barBorder
                    ],
                    borderWidth: 2
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: '# LLC owned Parcels',
                            font: {
                                size: axisTitle
                            }
                        } 
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Neighborhood',
                            font: {
                                size: axisTitle
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                      display: false
                    }
                  }
            }
        });

        const top_value = new Chart('top10value', {
            type: 'bar',
            data: {
                labels: ['Turtle Bay-East Midtown',
                'Hudson Yards-Chelsea-Flatiron-Union Square',
                'Midtown-Midtown South', 'SoHo-TriBeCa-Civic Center-Little Italy',
                'Hunters Point-Sunnyside-West Maspeth', 'East New York',
                'Battery Park City-Lower Manhattan',
                'Todt Hill-Emerson Hill-Heartland Village-Lighthouse Hill',
                'Flushing', 'New Springville-Bloomfield-Travis'],
                datasets: [{
                    label: 'Total Assessed Value (sum)',
                    data: [125682435, 120845190, 99625301, 95896785, 95221310, 73012207, 58036500,
                         46621960, 46470480, 41699255],
                    backgroundColor: [
                        barColor
                    ],
                    borderColor: [
                        barBorder
                    ],
                    borderWidth: 2
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return '$' + value.toLocaleString();
                            }
                        },
                        title: {
                            display: true,
                            text: 'Sum of Total Assessed Value ($)',
                            font: {
                                size: axisTitle
                            }
                        }  
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Neighborhood',
                            font: {
                                size: axisTitle
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                        callbacks: {
                            // include dollar sign on tooltip hover
                            label: function(context) {
                                var label = context.dataset.label || '';
        
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits:0 }).format(context.parsed.y);
                                }
                                return label;
                                }
                              }
                            }
                  }
            }
        });

        const topland = new Chart('top10land', {
            type: 'bar',
            data: {
                labels: ['New Springville-Bloomfield-Travis',
                'Charleston-Richmond Valley-Tottenville', 'East New York',
                'Hunters Point-Sunnyside-West Maspeth',
                'Mariners Harbor-Arlington-Port Ivory-Graniteville',
                'Stapleton-Rosebank',
                'Todt Hill-Emerson Hill-Heartland Village-Lighthouse Hill',
                'College Point', 'Annadale-Huguenot-Princes Bay-Eltingville',
                'Whitestone'],
                datasets: [{
                    label: 'Sum of Total Land Area (sqft)',
                    data: [23130537, 12859355, 4351195, 4272285, 3959920, 2941035, 2935807, 2650359, 2117838, 2104011],
                    backgroundColor: [
                        barColor
                    ],
                    borderColor: [
                        barBorder
                    ],
                    borderWidth: 2
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Sum of Total Land Area (sqft)',
                            font: {
                                size: axisTitle
                            }
                        }    
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Neighborhood',
                            font: {
                                size: axisTitle
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                      display: false
                    }
                }
            }
        });
    });

  return(
    <div className='main-page'>
    <div className='page-container'>
        <div className='city-text' id= 'city-text'>
            <h1> Citywide Statistics </h1>
            <h3 className='about-subtitle'> By the Numbers: </h3>
            <div className='centered'>
                <p>
                    There currently are <strong>26,576</strong> vacant or underutilized parcels in NYC. Of these <strong>26,576</strong> parcels,
                    <strong> 18,201</strong> are vacant and <strong>8,375</strong> are underutilized. 
                <br />
                <br />
                    LLCs currently own <strong>8,209</strong> of the vacant and underutilized parcels in NYC, <strong>30.9%</strong> of the total. This may already seem like a substantial amount, but
                    the numbers are even more staggering when comparing total assessed values or total land area. 
                <br />
                <br />
                    The total assessed value of all vacant and underutilized parcels in NYC sums to <strong>$3,349,346,839</strong>.
                    Of this total, LLCs own <strong>$1,961,360,890</strong>, which is <strong>58.6%</strong> of the total assessed value of vacant and underutilized parcels in NYC.
                <br />
                <br />    
                    This ratio is similar when comparing total square footage;
                    there is a total of <strong>273,231,462</strong> square feet of vacant and underutilized land in NYC, of which LLCs own <strong>115,176,337</strong> square feet, or <strong>42.2% </strong>of the total land area in square feet.
                <br />
                <br /> 
                Below, you can find the top 10 neighborhoods in NYC according to some of these statistics.
                </p>

            </div>
            <h3 className='about-subtitle'>Number of LLC-owned vacant & underutilized parcels </h3>
            <div className='chart-overflow'>
                <div className='stat-chart'>
                    <canvas className='chart' id='top10count'></canvas>
                </div>
            </div>    
            <h3 className='about-subtitle'> Sum of total assessed value owned by LLCs (Vacant & Underutilized) </h3>
            <div className='chart-overflow'>
                <div className='stat-chart'>
                    <canvas className='chart' id='top10value'></canvas>
                </div>
            </div>
            <h3 className='about-subtitle'> Sum of total land area owned by LLCs </h3>
            <div className='chart-overflow'>
                <div className='stat-chart'>
                    <canvas className='chart' id='top10land'></canvas>
                </div>
            </div>    
        </div>
    </div>
    </div>
)}
