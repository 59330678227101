import React, { useRef, useEffect, useState } from "react";
import "./topbar.css";
import { Link } from 'react-router-dom';

export default function Topbar() {

    useEffect(() => {
        const menuBtn = document.getElementById('menu-btn');
        const dropDown = document.getElementById('dropdown');
        let buttonOpen = false;
        
        /* open menu */
        menuBtn.addEventListener('click', () => {
            if(!buttonOpen) {
                menuBtn.classList.add('open');
                dropDown.style.top = '70px';
                buttonOpen = true;
            } else {
                menuBtn.classList.remove('open');
                dropDown.style.top = '-470px';
                buttonOpen = false;
            }
        });

        /* reset menu on option click */
        const menuOption = document.querySelectorAll('.menu-option');
        menuOption.forEach(item => {
            item.addEventListener('click', () => {
                        menuBtn.classList.remove('open');
                        dropDown.style.top = '-470px';
                        buttonOpen = false;
            });
        }); 
    });

    return (
        <div className="topbar">
            <img className="logo" src="assets/cptr_logo.png" alt="CPTR Logo"></img>
            <Link to="/" style={{textDecoration:"none"}}>
                <img className="website-logo" src="assets/nyc_vacant_logo2.png" alt="NYC Logo"></img>
            </Link>   
            <div className="menu-btn" id="menu-btn">
                <div className="menu-btn_burger">
                </div>
            </div>
        </div>
    )
}


