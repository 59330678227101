import React, { useState } from 'react'
import './maplegend.css';

export default function MapLegend({vacantType}) {
    return (
        <div className='legend-container' id = 'big-legend'>
                <h4 className='legend-title'> % {vacantType} Owned by LLCs</h4>
            <div className='labels'>
                100% 75% 50% 25% 0%
                <span className='legend-bar'> </span>
            </div>
            <div className='nodata'>
                <div className='block'></div>
                <span> &nbsp;: N/A</span>
            </div>
        </div>
    )
}
          