import React, { useState, useEffect } from 'react';
import './selectmap.css';

export default function SelectMap({setVacantType}) {

    const handleClick = (mode) => {
        setVacantType(mode)
        var allButton = document.getElementById('allButton');
        var trueButton = document.getElementById('trueButton');
        var funcButton = document.getElementById('funcButton');
        var baseColor = 'lightgray';
        var activeColor = 'lightblue';
        if (mode == 'Vacant or Underutilized Parcels') {
          allButton.style.backgroundColor = activeColor;
          trueButton.style.backgroundColor = baseColor;
          funcButton.style.backgroundColor = baseColor;
        } else if (mode == 'Vacant Parcels') {
          allButton.style.backgroundColor = baseColor;
          trueButton.style.backgroundColor = activeColor;
          funcButton.style.backgroundColor = baseColor;
        } else if (mode == 'Underutilized Parcels') {
          allButton.style.backgroundColor = baseColor;
          trueButton.style.backgroundColor = baseColor;
          funcButton.style.backgroundColor = activeColor;
        } else {
          allButton.style.backgroundColor = baseColor;
          trueButton.style.backgroundColor = baseColor;
          funcButton.style.backgroundColor = baseColor;
        }
      }

  return( 
      <div className='choropleth-container' id='choropleth-container'>
        <button className='map-button' id = 'allButton' onClick={()=>handleClick('Vacant or Underutilized Parcels')}> Vacant or Underutilized </button>
        <button className='map-button' id = 'trueButton' onClick={()=>handleClick('Vacant Parcels')}> Vacant Parcels </button>
        <button className='map-button' id = 'funcButton' onClick={()=>handleClick('Underutilized Parcels')}> Underutilized Parcels </button>
      </div>
)}
