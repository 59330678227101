import React, { Component } from 'react';
import './bottombar.css';
import { Link } from 'react-router-dom';

export default class BottomBar extends Component {
  render() {
    return (
      <div className="bottomBar">
        <div className="rsfLink">
          <a href="https://schalkenbach.org" target="_blank" rel="noopener noreferrer">
            Robert Schalkenbach Foundation
          </a>
        </div>
        <div className="cptrLink">
          <a href="https://centerforpropertytaxreform.org" target="_blank" rel="noopener noreferrer">
            Center for Property Tax Reform
          </a>
        </div>
        <div className="sourcesLink">
          <Link to="/Sources">
            <span>Sources</span>
          </Link>  
        </div>
      </div>
    );
  }
}