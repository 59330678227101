import React from 'react';
import './about.css'

export default function About() {
  return(
    <div className='page-container' id= 'page-read'>
        <div className='about-text' id= 'about-text'>
          <h1> About LLCs in NYC </h1>
          <h3 className='about-subtitle'>An Introduction to LLCs in NYC</h3>
          <div className='centered'>
            <p>
              This mapping tool was created to bring transparency to a system that is inherently and intentionally opaque: Limited Liability Corporation (LLC) ownership of properties in New York City.  
              <br/>
              <br/>
              With the “LLCs in NYC” tool, anyone can identify which “vacant” or “blighted” parcels in the City are owned by LLCs and which are in private hands.  We have designed the tool to provide both high-level, summary views of ownership trends in the City, as well as give users the ability to drill down to the level of individual parcels for the purpose of understanding how LLC-owned properties mesh with and affect the fabric of New York’s distinct neighborhoods.
            </p>
          </div>
          <h3 className='about-subtitle'>What are LLCs?</h3>
            <div className='centered'>
            <p>
              In comparison to the traditional corporate structure, LLCs are a relatively new legal-organizational form.  Created in response to the slow economic growth and high inflation of the 1970s, the first such entity appeared in Wyoming in the late 1970s. (Hamill 1998)  It wasn’t until much more recently, however, that the prevalence of LLCs truly exploded nationwide - increasing some 750% between 1990 and 2012. (Soener and Nau 2019)  
              <br/>
              <br/>
              What has contributed to the popularity of LLCs?  Quite simply, they combine the benefits of a traditional corporate structure with enhanced anonymity and reduced tax liabilities: a win-win for investors looking to limit personal risk while seeking big profits in real estate. (Bosko 1993)  
              <br/>
              <br/>
              Renowned for its real estate market, our research into NYC’s public tax records shows that the City is home to some 90,560 unique LLCs.  While they are not evenly distributed, every single one of the the City’s 195 neighborhoods has them, with the tax records of some, such as East New York reflecting the presence of literally hundreds of LLCs within their boundaries.
            </p>
          </div>  
          <h3 className='about-subtitle'>Who Benefits from LLCs?</h3>
          <div className='centered'>
          <p>
            While technically, virtually anyone can create an LLC, research shows that only a very rarefied few actually do.  Who are these select investors?  Overwhelmingly, LLCs are owned by people in the top 1% of the income and wealth distributions, the vast majority of whom are white and male. (Soener and Nau 2019)  By way of example, Donald Trump’s holdings in at least 96 LLCs - many in New York City - contributed significantly to his ability to withhold personal financial information during the 2016 election. (Jean Eaglesham and Schwartz 2016)
          </p>
          </div>
          <h3 className='about-subtitle'>How are LLCs and Land Speculation Connected?</h3>
          <div className='centered'>
          <p>
            Land speculation is defined as the “holding of idle or underutilized land as an asset in the hopes of attaining value increments.” (Eckart 1983)  Speculators see land purely as a commodity - benefiting from, but not enhancing its qualities as a non-substitutable locus of social and economic activity within a community - and specifically act to remove wealth from the local environment for personal gain. (Gemeda et al. 2021; Lindeman 1976; Stanley 2017)  Given the arm’s length, extractive nature of the speculator’s relationship to the places they buy property, it’s not hard to see why the anonymity and tax advantages offered by an LLC can make it an ideal choice for this type of investor.
            <br/>
            <br/>
            In addition to generally setting the stage for vacancy and blight, land speculation has also been shown to increase the residential rents and decrease housing supply (Eckart 1983).  And LLCs, in particular, represent an appealing structure for land speculation, as the anonymity they afford makes it next to impossible for tenants to push back on bad landlords, or city officials to go after owners who fail to maintain their properties (Badger 2018).  These outcomes are concerning for any community, and New York City is no different.
          </p>
          </div>
          <h3 className='about-subtitle'>LLCs in New York City: Trends and Concerns:</h3>
          <div className='centered'>
          <p>
            The prevalence of LLC-owned properties in NYC is no secret, but the motivations for combatting the trend are varied.
            <br/>
            <br/>
            Spurred by the Russian invasion of Ukraine and subsequent U.S. sanctions, New York State lawmakers have very recently proposed <a href='https://www.nysenate.gov/legislation/bills/2021/s8439' target="_blank" rel="noopener noreferrer">a law</a> that would force individuals wishing to purchase property in the City through an LLC to disclose their identity to the State and on their tax returns.  This action was not motivated by land use or housing affordability concerns, but by a desire to do away with a tax shelter favored by Russian oligarchs to hide vast sums of wealth within the City’s real estate market. (Warren 2022)  The notion that the benefits of the LLC structure are used in unsavory ways is nothing new in NYC (Maritz 2021), but this legislation represents an important turning point in the evolution of this entity type as it would effectively do away with the owners’ ability to remain unnamed in New York.
            <br/>
            <br/>
            Of course, efforts to see beyond the LLC veil predate the current geopolitical climate.  The presence of Limited Liability Corporations in New York has garnered considerable attention from Councilpeople and others concerned with the proliferation of vacant storefronts in the City, which has only worsened as a result of the pandemic. (Fertig 2021)  As previously noted, without the ability to know who owns these underutilized properties, it’s virtually impossible to exert pressure on them to return the spaces to productive use.  In an attempt to remedy the situation, <a href='https://legistar.council.nyc.gov/LegislationDetail.aspx?ID=3877905&GUID=2C710489-FF37-4DE8-BA81-8F647F174F49&Options=ID%7cText%7c&Search=' target="_blank" rel="noopener noreferrer">legislation</a> was introduced in 2019, which if passed, would require owners of vacant storefronts appear on a citywide registry, making it easier to know who is responsible for their obsolescence.  
            <br/>
            <br/>
            So it seems that greater transparency, and in turn, greater accountability, may well be coming for the owners of LLCs.  But just because information is legally available, doesn’t mean it’s accessible to everyone who might benefit from accessing it.  That’s where CPTR’s “LLCs in NYC” mapping project comes in.  We’ve done the work to identify the vacant and underutilized parcels in every neighborhood in New York City and display them in an interactive map that allows anyone, anywhere to see if they’re held by a private owner or an LLC.  
            <br/>
            <br/>
            Why?  Because we’re committed to transparency and equity in taxation, and sometimes that means building tools that shine the light on privilege and the harm it causes.  We hope that this tool, and the information in contains, will be used by organizations committed to bringing more affordable housing to NYC, to filling idle storefronts, and to reincorporating unproductive parcels into the fabric of the City’s neighborhoods.
            <br/>
            <br/>
          </p>
          </div>
          <h3 className='about-subtitle'>References</h3>
          <div className='centered'>
            <li className='cite'>Badger, Emily. 2018. “Anonymous Owner, L.L.C.: Why It Has Become So Easy to Hide in the Housing Market.” The New York Times, April 30, 2018. <a href='https://www.nytimes.com/2018/04/30/upshot/anonymous-owner-llc-why-it-has-become-so-easy-to-hide-in-the-housing-market.html' target="_blank" rel="noopener noreferrer">https://www.nytimes.com/2018/04/30/upshot/anonymous-owner-llc-why-it-has-become-so-easy-to-hide-in-the-housing-market.html</a>.</li>
            <li className='cite'>Bosko, Marybeth. 1993. “The Best of Both Worlds: The Limited Liability Company.” Ohio St. LJ 54: 175.</li>
            <li className='cite'>Eckart, Wolfgang. 1983. “Land Speculation and the Rental Price of Housing.” Journal of Urban Economics 13 (1): 1–21.</li>
            <li className='cite'>Fertig, Beth. 2021. “Vacant Storefronts Proliferate in NYC, and It’s No Easier to Identify Owners.” Gothamist. April 14, 2021. <a href='https://gothamist-client.prod.nypr.digital/news/vacant-storefronts-proliferate-in-nyc-and-its-no-easier-to-identify-owners?mc_cid=1c6716a2e3' target="_blank" rel="noopener noreferrer">https://gothamist-client.prod.nypr.digital/news/vacant-storefronts-proliferate-in-nyc-and-its-no-easier-to-identify-owners?mc_cid=1c6716a2e3</a>.</li>
            <li className='cite'>Gemeda, B. S., B. Girma Abebe, and F. Eckardt. 2021. “Urban Land Speculation; Failure of Land Market.” Survey Review 53 (376): 1–7.</li>
            <li className='cite'>Hamill, Susan Pace. 1998. “The Origins behind the Limited Liability Company.” Ohio St. LJ 59: 1459.</li>
            <li className='cite'>Jean Eaglesham, Mark Maremont, and Lisa Schwartz. 2016. “How Donald Trump’s Web of LLCs Obscures His Business Interests.” Wall Street Journal (Eastern Ed.), December 8, 2016. <a href='https://www.wsj.com/articles/how-donald-trumps-web-of-llcs-obscures-his-business-interests-1481193002' target="_blank" rel="noopener noreferrer">https://www.wsj.com/articles/how-donald-trumps-web-of-llcs-obscures-his-business-interests-1481193002</a>.</li>
            <li className='cite'>Lindeman, Bruce. 1976. “Anatomy of Land Speculation.” Journal of the American Institute of Planners 42 (2): 142–52.</li>
            <li className='cite'>“Long A New York Real Estate Mainstay, LLCs Lose Their Anonymity.” 2021. Gothamist. January 8, 2021. <a href='https://gothamist.com/news/long-new-york-real-estate-mainstay-llcs-lose-their-anonymity' target="_blank" rel="noopener noreferrer">https://gothamist.com/news/long-new-york-real-estate-mainstay-llcs-lose-their-anonymity</a>.</li>
            <li className='cite'>“NYS Lawmakers Introduce Legislation to Monitor LLCs.” 2022. Warren Law Group. March 7, 2022. <a href='https://warren.law/2022/03/07/nys-lawmakers-introduce-legislation-to-monitor-llcs/'  target="_blank" rel="noopener noreferrer">https://warren.law/2022/03/07/nys-lawmakers-introduce-legislation-to-monitor-llcs/</a>.</li>
            <li className='cite'>Soener, Matthew, and Michael Nau. 2019. “Citadels of Privilege: The Rise of LLCs, LPs and the Perpetuation of Elite Power in America.” Economy and Society 48 (3): 399–425.</li>
            <li className='cite'>Stanley, Benjamin W. 2017. “The Political Economy of Land Speculation in Downtown Phoenix.” In Transparent Urban Development: Building Sustainability Amid Speculation in Phoenix, edited by Benjamin W. Stanley, 187–237. Cham: Springer International Publishing.</li>
          </div>
        </div>
    </div>
  )
}
