import React, { useRef, useEffect, useState } from 'react';
import Mappage from "./map/Mappage";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Topbar from "./topbar/Topbar";
import Menu from './menu/Menu';
import About from './about/About';
import Sources from './sources/Sources';
import Stats from './stats/Stats';

export default function App() {
  const [vacantType, setVacantType] = useState('Vacant or Underutilized Parcels');
  return (
    <Router>
      <div>
        <Topbar />
        <Menu />
        <Routes>
          <Route path="/" element={<Mappage setVacantType={setVacantType} vacantType={vacantType} />} />
          <Route path="/About" element={<About />} />
          <Route path="/Sources" element={<Sources />} />
          <Route path="/Stats" element={<Stats />} />
        </Routes>
      </div>
    </Router>
  )
}
