import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "./mappage.css"
import 'mapbox-gl/dist/mapbox-gl.css';
import BottomBar from '../bottombar/BottomBar';
import nyc_llc from "../data/final_all_vacant.geojson";
import parcel_source from "../data/final_all_parcels.geojson";
import func_vacant from "../data/final_func_vacant.geojson";
import true_vacant from "../data/final_true_vacant.geojson";
import MapLegend from "../legend/MapLegend";
import SmallLegend from "../legend/SmallLegend";
import Tutorial from '../tutorial/Tutorial';
import SelectMap from "../select_map/SelectMap";
import { Animator, Chart, registerables } from 'chart.js';
Chart.register(...registerables);

mapboxgl.accessToken = 'pk.eyJ1Ijoicm9iZXJ0c2NoYWxrZW5iYWNoZm91bmRhdGlvbiIsImEiOiJjazUybHJwZ2kxOTBxM25xcnE1MTdiOTc1In0.TltYEYXKnQTW2OMMbGoeYQ';

var llc_color = '#FF8D47';
var non_llc_color = '#62d1fc';
var i = 0; /* used to hold chart id  */

export default function Mappage({vacantType, setVacantType}) {
  const mapContainer = useRef(null);
  const bounds = [
    [-74.8038, 40.0254], // Southwest coordinates
    [-72.8629, 41.3193] // Northeast coordinates
    ];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/robertschalkenbachfoundation/ckyqhfhqw43mm15pqh1g8x4ee',
      center: [-73.9700, 40.7128],
      zoom: 10,
      minZoom: 9,
      maxBounds: bounds,
      attributionControl: false
    });
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    const zoomThreshold = 15;

    function addDataLayers(var1, filter, filter2) {
      map.addSource('nyc_llc', {
        'type': 'geojson',
        'data': var1,
        'generateId': true
      });
      map.addSource('parcel_source', {
          'type': 'geojson',
          'data': parcel_source,
          'generateId': true
      });   
      map.addLayer({
        id: 'satellite',
        source: {"type": "raster",  "url": "mapbox://mapbox.satellite", "tileSize": 256},
        type: "raster",
        layout: {
          visibility: 'none'
        }
      });
      map.addLayer({
        'id': 'nyc-layer',
        'type': 'fill',
        'maxzoom': zoomThreshold,
        'source':'nyc_llc',
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'fill-color': 
          ["case",
            ["==", ["get", "perc_llc"], null],
            "#828282",
            ['interpolate',
            ['linear'], 
            ['get', 'perc_llc'],
            0, '#fef0d9',
            0.25, '#fdcc8a',
            0.5, '#fc8d59',
            0.75, '#e34a33',
            1, '#b30000',
           ]], 
          'fill-opacity-transition': {'duration': 700},
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.8
            ]
        }
      });
      map.addLayer({
        'id': 'nyc-line',
        'type': 'line',
        'source':'nyc_llc',
        'paint': {
          'line-color': {
            "stops": [
                // zoom is 5 -> circle radius will be 1px
                [15, 'white'],
                // zoom is 10 -> circle radius will be 2px
                [15.001, 'black']
            ]
        },
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.8
            ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15, 1,
            23, 8
            ]
        }
      });
      map.addLayer({
        'id': 'zoom-layer',
        'type': 'fill',
        'minzoom': zoomThreshold,
        'source':'parcel_source',
        'layout': {},
        'filter': [filter, 'TRUE_VACAN', filter2],
        'paint': {
          'fill-color': [
            'match',
            ['get', 'LLC_OWNER'],
            1,
            llc_color,
            0,
            non_llc_color,
            /* other */ '#ccc'
            ],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.4,
            0.9
            ]
        }
      });
      map.addLayer({
        'id': 'zoom-line',
        'type': 'line',
        'minzoom': zoomThreshold,
        'source':'parcel_source',
        'layout': {},
        'filter': [filter, 'TRUE_VACAN', filter2],
        'paint': {
          'line-color': 'white',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15, 0.8,
            23, 9
            ]
        }
      });
      map.addLayer({
        'id': 'nta-name',
        'type': 'symbol',
        'source': 'nyc_llc',
        'layout': {
          'text-field': ['get', 'ntaname'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'icon-image': ['get', 'icon'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            8, 9,
            23, 24
            ]
          },
        'paint': {
          "text-color": 'black',
          "text-halo-width": [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            2
            ],
          "text-halo-color": 'white'
        }
      });
    }

    map.on("load", () => {
      if (vacantType === 'Vacant or Underutilized Parcels') {
          addDataLayers(nyc_llc, '!=', 'null');
          var allButton = document.getElementById('allButton');
          allButton.style.backgroundColor = 'lightblue';
      } else if (vacantType === 'Vacant Parcels') {
          addDataLayers(true_vacant, '==', 1);
          var trueButton = document.getElementById('trueButton');
          trueButton.style.backgroundColor = 'lightblue';
      } else if (vacantType === 'Underutilized Parcels') {
          addDataLayers(func_vacant, '==', 0);
          var funcButton = document.getElementById('funcButton');
          funcButton.style.backgroundColor = 'lightblue';
      } else {
          setVacantType('Vacant or Underutilized Parcels');
          allButton.style.backgroundColor = 'lightblue';
      }
    });
    
      /* choropleth popup */
      //var i = 0; /* needed for charts */
      map.on('click', 'nyc-layer', (e) => {  
        console.log(i)
        if (e.features[0].properties.num_llc === undefined & e.features[0].properties.num_non_llc === undefined) {
          return;
        } else {
          const neighborhood = e.features[0].properties.ntaname;
          const num_llc = e.features[0].properties.num_llc;
          const num_not_llc = e.features[0].properties.num_non_llc;
          const non_llc_impval_sum = e.features[0].properties.non_llc_impval;
          const llc_impval_sum = e.features[0].properties.llc_impval;
          const non_llc_landval_sum = e.features[0].properties.non_llc_landval;
          const llc_landval_sum = e.features[0].properties.llc_landval;
          const non_llc_sqft_avg = e.features[0].properties.non_llc_sqft_avg;
          const llc_sqft_avg = e.features[0].properties.llc_sqft_avg;
          const non_llc_bldg_avg = e.features[0].properties.non_llc_bldg_avg.toFixed(2);
          const llc_bldg_avg = e.features[0].properties.llc_bldg_avg.toFixed(2);
          const non_llc_subway_avg = e.features[0].properties.non_llc_subway_avg;
          const llc_subway_avg = e.features[0].properties.llc_subway_avg;
          const non_llc_impval_avg = e.features[0].properties.non_llc_imp_avg;
          const llc_impval_avg = e.features[0].properties.llc_imp_avg;
          const non_llc_landval_avg = e.features[0].properties.non_llc_landval_avg;
          const llc_landval_avg = e.features[0].properties.llc_landval_avg;

        new mapboxgl.Popup({anchor: 'bottom', closeOnClick:'true'})
          .setLngLat(e.lngLat)
          .setHTML(
            '<div class="popup">' +
              '<div class = "popupTitle"><strong> ' + neighborhood + '</strong></div>' +
              '<div class = "sectionTitle"> ' + vacantType + '</div>' +
              '<div class = "subTitle"> Number of '+ vacantType + ' Owned: </div>' +
              '<canvas width="230px" height ="150px" id="piechart' + i + '"></canvas>' +
              '<div class = "subTitle">Average '+ vacantType + ' Land Value: </div>' +
              '<canvas width="230px" height ="150px" id="foo' + i + '"></canvas>' +
              '<div class = "subTitle">Average '+ vacantType + ' Improvement Value:</div>' +
              '<canvas width="230px" height ="150px" id="avg_imp_chart' + i + '"></canvas>' +
              '<div class = "subTitle">Average Square Feet:</div>' +
              '<canvas width="230px" height ="150px" id="sq_feet_chart' + i + '"></canvas>' +
              '<div class = "subTitle">Average Number of Buildings:</div>' +
              '<canvas width="230px" height ="150px" id="num_build_chart' + i + '"></canvas>' +
              '<div class = "subTitle">Average Distance to Nearest Subway (feet):</div>' +
              '<canvas width="230px" height ="150px" id="subway_chart' + i + '"></canvas>' +
            '</div>'
          )
          .addTo(map);
        
        /* creates variable for each individual chart when clicked */
        var pie = document.getElementById('piechart' + i).getContext('2d');  
        var ctx = document.getElementById('foo' + i).getContext('2d');
        var avg_imp_chart = document.getElementById('avg_imp_chart' + i).getContext('2d');
        var sq_feet_chart = document.getElementById('sq_feet_chart' + i).getContext('2d');
        var num_build_chart = document.getElementById('num_build_chart' + i).getContext('2d');
        var subway_chart = document.getElementById('subway_chart' + i).getContext('2d');

        /* number vacant properties chart */
        var chart1 = new Chart(pie, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [num_llc, num_not_llc]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                },
          });
        
        /* average land value chart */
        var chart2 = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    label: 'Average Land Value',
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [Math.round(llc_landval_avg), Math.round(non_llc_landval_avg)]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      callbacks: {
                          // include dollar sign on tooltip hover
                          label: function(context) {
                              var label = context.dataset.label || '';
      
                              if (label) {
                                  label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits:0 }).format(context.parsed.y);
                              }
                              return label;
                              }
                            }
                          }
                  },
                  scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return '$' + value.toLocaleString();
                            }
                        }
                    }
                  }
                },
          });

          /* average improvement value chart */
          var chart3 = new Chart(avg_imp_chart, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    label: 'Avg. Imp. Value',
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [Math.round(llc_impval_avg), Math.round(non_llc_impval_avg)]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      callbacks: {
                          label: function(context) {
                              var label = context.dataset.label || '';
      
                              if (label) {
                                  label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits:0 }).format(context.parsed.y);
                              }
                              return label;
                              }
                            }
                          }
                  },
                  scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return '$' + value.toLocaleString();
                            }
                        }
                    }
                  }
                },
          });

          /* square footage chart */
          var chart4 = new Chart(sq_feet_chart, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    label: ['Average Square Feet'],
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [Math.round(llc_sqft_avg), Math.round(non_llc_sqft_avg)]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                },
          });

          /* # buildings chart */
          var chart5 = new Chart(num_build_chart, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    label: ['Average Number of Buildings'],
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [llc_bldg_avg, non_llc_bldg_avg]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                },
          });

          /* subway chart */
          var chart6 = new Chart(subway_chart, {
            type: 'bar',
            data: {
                labels: ['LLC', 'Non-LLC'],
                datasets: [{
                    label: ['Avg Distance to Subway in Feet'],
                    backgroundColor: [llc_color, non_llc_color],
                    borderColor: 'black',
                    data: [Math.round(3.28084 * llc_subway_avg), Math.round(3.28084 * non_llc_subway_avg)]
                }]
              },
            options: {
                  maintainAspectRatio: false,
                  responsive: false,
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                },
          });
          i++;  
        }
      });

      /* parcel popup */
      map.on('click', 'zoom-layer', (e) => {
        const address = e.features[0].properties.ADDRESS;
        const owner = e.features[0].properties.OWNERNAME;
        const zoning = e.features[0].properties.ZONEDIST1;
        const landval = e.features[0].properties.ASSESSLAND;
        const impval = e.features[0].properties.ASSESSIMPV;
        const numbuild = e.features[0].properties.NUMBLDGS;
        const sqft = e.features[0].properties.LOTAREA;
        const landuse = e.features[0].properties.LANDUSE_DE;
        const subway_dist = e.features[0].properties.CLOSEST_SU;
        var vacantClass = '';

        if (landuse == 'Vacant Land') {
          vacantClass = 'Vacant';
        } else {
          vacantClass = 'Underutilized';
        };

        const popupHTML = `
          <div class="popup">
              <div class = "popupTitle"><strong> ${address}</strong></div>
              <div class = "sectionTitle">${(vacantClass)} Parcel</div>
                  <div class = "subTitle">Owner:</div>
                  <div class="data">${(owner)}</div>
                  <div class = "subTitle">Land Value:</div>
                  <div class="data">$ ${Math.round(landval).toLocaleString()} </div>
                  <div class = "subTitle">Improvement Value:</div>
                  <div class="data">$ ${Math.round(impval).toLocaleString()} </div>
                  <div class = "subTitle">Number of Buildings:</div>
                  <div class="data">${(numbuild)} </div>
                  <div class = "subTitle">Area in Square Feet:</div>
                  <div class="data">${Math.round(sqft).toLocaleString()} </div>
                  <div class = "subTitle">Current Zoning:</div>
                  <div class="data">${(zoning)} </div>
                  <div class = "subTitle">Usage description:</div>
                  <div class="data">${(landuse)}</div>
                  <div class = "subTitle">Distance to nearest Subway (ft):</div>
                  <div class="data">${(Math.round(subway_dist).toLocaleString())}</div>
                  
          </div>`;
        new mapboxgl.Popup({anchor: 'bottom', closeOnClick:'true'})
          .setLngLat(e.lngLat)
          .setHTML(popupHTML)
          .addTo(map);
      });

      /* Updates Legend on Zoom */
      const bigLegend = document.getElementById('big-legend');
      const smallLegend = document.getElementById('small-legend');

      map.on('zoom', () => {
        if (map.getZoom() > zoomThreshold) {
          bigLegend.style.opacity = 0;
          smallLegend.style.opacity = 1;
        } else {
          bigLegend.style.opacity = 1;
          smallLegend.style.opacity = 0;
        }
      });

      /* Flies to click */
      map.on('click', 'nyc-layer', (e) => {
        if (window.innerHeight < 850) {
          console.log(e.lngLat.lat);
          map.flyTo({
            center: [e.lngLat.lng, (e.lngLat.lat + 0.01)],
            speed: 0.25,
            essential: true
          });
       }
       else {
          map.flyTo({
          center: e.lngLat,
          speed: 0.25,
          essential: true
        });
       }
        
      });
      map.on('click', 'zoom-layer', (e) => {
        map.flyTo({
          center: e.lngLat,
          speed: 0.25,
          essential: true
        });
      });

      /* change pointer when object hovered*/
      map.on('mouseenter', 'nyc-layer', () => {
        map.getCanvas().style.cursor = 'pointer';
      });   
      map.on('mouseleave', 'nyc-layer', () => {
        map.getCanvas().style.cursor = '';
      });

      map.on('mouseenter', 'zoom-layer', () => {
        map.getCanvas().style.cursor = 'pointer';
      }); 
      map.on('mouseleave', 'zoom-layer', () => {
        map.getCanvas().style.cursor = '';
      });

      /* choropleth Hover fill effect */
      let quakeID = null;
        map.on('mousemove', 'nyc-layer', (e) => {
          if (quakeID) {
            map.removeFeatureState({
              source: 'nyc_llc',
              id: quakeID
            });
          }

          quakeID = e.features[0].id;
          map.setFeatureState(
            {
              source: 'nyc_llc',
              id: quakeID
            },
            {
              hover: true
            }
          );
        });

        map.on('mouseleave', 'nyc-layer', () => {
          if (quakeID) {
            map.setFeatureState(
              {
                source: 'nyc_llc',
                id: quakeID
              },
              {
                hover: false
              }
            );
          }
          quakeID = null;
        });
        /* end hover effect */

        /* parcel hover effect */
        let parcelID = null;
        map.on('mousemove', 'zoom-layer', (e) => {
          if (parcelID) {
            map.removeFeatureState({
              source: 'parcel_source',
              id: parcelID
            });  
          }

          parcelID = e.features[0].id;
          map.setFeatureState(
            {
              source: 'parcel_source',
              id: parcelID
            },
            {
              hover: true
            }
          );
        });

        map.on('mouseleave', 'zoom-layer', () => {
          if (parcelID) {
            map.setFeatureState(
              {
                source: 'parcel_source',
                id: parcelID
              },
              {
                hover: false
              }
            );
          }
          parcelID = null;
        });
        /* end hover effect */

        /* toggles base layer */
        var switchy = document.getElementById('remover');
        switchy.onclick =  function() {
          if (switchy.className === 'on') {
            switchy.setAttribute('class', 'off');
            map.setLayoutProperty('satellite', 'visibility', 'none');
            switchy.innerHTML = 'Satellite View';
          } else {
            switchy.setAttribute('class', 'on');
            map.setLayoutProperty('satellite', 'visibility', 'visible');
            switchy.innerHTML = 'Street View';
          }
        }

        var help = document.getElementById('toggleHelp');
        help.onclick =  function() {
          var helpWindows = document.getElementById('modalContent');
          if (help.className === 'on') {
            help.setAttribute('class', 'off');
            help.style.backgroundColor = 'white';
            helpWindows.style.visibility = 'hidden';
            helpWindows.style.opacity = '0';
          } else {
            help.setAttribute('class', 'on');
            help.style.backgroundColor = 'lightblue';
            helpWindows.style.visibility = 'visible';
            helpWindows.style.opacity = '1';
          }
        }
  });

  return (
    <div className = 'map-wrap'>      
      <SelectMap setVacantType={setVacantType} />
      <SmallLegend vacantType={vacantType} />
      <MapLegend vacantType={vacantType} />
      <BottomBar />
      <button id='toggleHelp' className='off'>
        Toggle Help Windows
      </button>
      <button id='remover' className='off'>
        Satellite View
      </button>
      <div ref={mapContainer} className="map-container" />
      <Tutorial />
    </div>
  );
}
