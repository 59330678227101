import React, { Component } from 'react';
import './tutorial.css';

export default function Tutorial() {
    return (
      <div className="modalContent" id="modalContent">

        <div
          id="choroplethTip"
          className="tip arrow-choropleth"
        >
          Click on any neighborhood to view summary statistics for that neighborhood.
        </div>

        <div
          id="zoomTip"
          className="tip arrow-zoom"
        >
          Click and drag to move the map.
          <br />
          <br />
          Scroll to zoom in and reveal individual parcels.
          <br />
          <br />
          Click on a parcel to view it's details
        </div>

        <div
          id="menuTip"
          className="tip arrow-menu"
        >
          Click the hamburger style button in the top right corner to open the menu
        </div>

        <div
          id="toggleTip"
          className="tip arrow-toggle"
        >
          Click this button to toggle between street view and satellite view
        </div>

        <div
          id="legendTip"
          className="tip arrow-legend"
        >
          Toggle the buttons on the legend to view different parcel categories. This updates across all levels of zoom.
        </div>

      </div>  
)}