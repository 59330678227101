import "./menu.css";
import { Link } from 'react-router-dom';


export default function Menu() {
  return (
    <div className="menu" id='dropdown'>
      <ul>
        <li className="menu-block">
          <Link to="/" style={{textDecoration:"none"}}>
            <span className='menu-option'>Map</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link to="/About" style={{textDecoration:"none"}}>
            <span className='menu-option'>About</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link to="/Stats" style={{textDecoration:"none"}}>
            <span className='menu-option'>Citywide Statistics</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link to="/Sources" style={{textDecoration:"none"}}>
            <span className='menu-option'>Sources & Methods</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}