import React from 'react'
import './smalllegend.css'

export default function SmallLegend({vacantType}) {
    return (
        <div className='small-container' id = 'small-legend'>
                <h4 className='legend-title'> {vacantType} </h4>
            <div className='parcel-legend'>
                <div className='parcel-contain'>
                    <div className='llc-parcel'></div>
                    <span> &nbsp;: LLC-Owner</span>
                </div>
                <div className='parcel-contain'>
                    <div className='nonllc-parcel'></div>
                    <span> &nbsp;: Non-LLC Owner</span>
                </div>
            </div>
        </div>
    )
}