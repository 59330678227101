import React from 'react';
import './sources.css';

export default function Sources() {
  return (
    <div className='main-page'>
    <div className='page-container'>
        <div className='about-text' id= 'about-text'>
            <h1> Sources & Methodology </h1>
            <h3 className='about-subtitle'>Parcel Data</h3>
            <div className='centered'>
                <p>
                    The parcel data used in this map was obtained from NYC Department of Finance's <a href='https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page' target="_blank" rel="noopener noreferrer">MapPLUTO</a> Digital Tax Map, release 21v4.
                </p>
            </div>
            <h3 className='about-subtitle'>Identifying LLC Owned Parcels</h3>
            <div className='centered'>
                <p>
                The parcel data obtained from NYC’s department of finance does not implicitly identify which parcels of land are owned by LLCs. MapPLUTO and the PLUTO dataset do have an owner field in the data, which we have used in this map to identify LLC owned parcels. We are identifying LLC owners based on records in the data that contain the following in the owner field (not case sensitive):
                </p>
            </div> 
            <div className='centered'>
                <li className='sources-list'>LLC</li>
                <li className='sources-list'>LLC with an allowance of a character between each letter (ex: L.L.C.)</li>
                <li className='sources-list'>Limited Liability</li>
                <li className='sources-list'>Limited Corp</li>
                <li className='sources-list'>LTD</li>
                <li className='sources-list'>Lastly, we recognize records where the owner field is, or is almost, identical to the address field as an LLC</li>
            </div> 
            <h3 className='about-subtitle'>Identifying Underutilized Parcels</h3>
            <div className='centered'>
                <p>
                New York City specifically identifies Vacant Land as a land use type in the PLUTO dataset, but we also categorized ‘Underutilized Land’. We are considering underutilized land to be surface parking and lots where buildings make up 15% or less of the total market value of the lot. See below for the specific identification criteria:
                </p>
            </div>
            <h4 className='list-title'>Surface Parking</h4>
            <div className='centered'>
                <li className='sources-list'>Land use 10 (Parking)</li>
                <li className='sources-list'>Building Area equal to 0</li>
                <li className='sources-list'>Number of Buildings on lot are less than or equal to 1</li>
                <li className='sources-list'>Assessed land value of greater than zero</li>
                <li className='sources-list'>Not fully tax-exempt</li>
                <li className='sources-list'>Not owned by the city or a public authority</li>
            </div>
            <h4 className='list-title'>Other Underutilized Lots</h4>
            <div className='centered'>
                <li className='sources-list'>Assessed improvement value is less than 15% of the total assessed value</li>
                <li className='sources-list'>Assessed land value is greater than zero</li>
                <li className='sources-list'>Total assessed value is greater than zero</li>
                <li className='sources-list'>Assessed improvement value is greater than zero</li>
                <li className='sources-list'>Doesn't have a land use code of 9 (Open space & outdoor recreation) or 11 (Vacant land)</li>
                <li className='sources-list'>Not fully tax-exempt</li>
                <li className='sources-list'>Not owned by the city or a public authority</li>
            </div> 
            <h3 className='about-subtitle'>Neighborhood Zones</h3>
            <div className='centered'>
                <p>
                    The neighborhood bounding areas used in the map are the <a href='https://data.cityofnewyork.us/City-Government/2010-Neighborhood-Tabulation-Areas-NTAs-/cpf4-rkhq' target="_blank" rel="noopener noreferrer">2010 Neighborhood Tabulation Areas</a> (NTAs), obtained from NYC OpenData.
                    This is a free, publicly available dataset that closely aligns with the actual neighborhoods of NYC. Based on the density and amount of vacant parcels in NYC, NTAs are the perfect size for visualizing the distribution of this data across the city.
                </p>
            </div>    
            <h3 className='about-subtitle'>Subway Locations</h3>
            <div className='centered'>
                <p>
                    The subway locations used to calculate the distance to the nearest subway stop for each lot were obtained from <a href='https://data.cityofnewyork.us/Transportation/Subway-Stations/arq3-7z49' target="_blank" rel="noopener noreferrer">NYC OpenData</a>.
                    Close proximity to public transit is a desirable trait for real estate in cities. We calculated the distance to the nearest subway for each vacant lot to compare trends between LLCs and non-LLC landowners in the city.
                </p>
            </div>
            <h3 className='about-subtitle'> Misc. Attribution</h3>
            <div className='centered'>
                <p>
                    The city image used in the logo is from the free image resource <a href='https://www.flaticon.com/free-icons/city' target="_blank" rel="noopener noreferrer">Freepik</a>.
                <br />
                <br />
                </p>
                
            </div>   
        </div>
    </div>
    </div>
)}
